import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import Select from 'react-select';
import axios from 'axios';

const TagManagement = (props: any) => {
  const {
    conferenceId,
    isModerator,
    getParticipantTagList,
    getFileList,
  } = props;
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [selectedTags, setSelectedTags] = useState<any>(null);
  const [selectTagThisSite, setSelectTagThisSite] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);

  const handleAddTag = () => {
    try {
      if (newTag) {
        axios
          .post(`/api/visitor/media/tags`, {
            label: newTag,
            conferenceId: conferenceId,
          })
          .then((response) => {
            setTags([...tags, { value: response.data.id, label: newTag }]);
            setNewTag('');
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTagClick = (tag: any) => {
    setSelectedTags(tag);
    setShowModal(true);
  };

  const handleChange = (e: any) => {
    setSelectedTags({ ...selectedTags, label: e.target.value?.trim() });
  };

  const handleEditTag = () => {
    if (!selectedTags.label) return;
    const updatedTags = tags.map((tag: any) => {
      if (tag.value === selectedTags?.value) {
        return { ...tag, ...selectedTags };
      }
      return tag;
    });
    setTags(updatedTags);

    try {
      axios
        .put(`/api/visitor/media/tags/${selectedTags.value}`, {
          label: selectedTags.label,
        })
        .then(() => {
          setShowModal(false);
          getParticipantTagList && getParticipantTagList();
          getFileList && getFileList();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteTag = () => {
    try {
      axios.delete(`/api/visitor/media/tags/${selectedTags.value}`).then(() => {
        setTags(tags.filter((tag: any) => tag.value !== selectedTags.value));
        setShowModal(false);
        getParticipantTagList && getParticipantTagList();
        getFileList && getFileList();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveSettings = () => {
    if (
      !showAllTags &&
      (!selectTagThisSite || selectTagThisSite?.length === 0)
    ) {
      alert('Please select at least one tag or check "Show all tags"');
      return;
    }
    try {
      axios
        .post(`/api/visitor/media/tags/settings/${conferenceId}`, {
          showAllTags,
          selectedTags: selectTagThisSite ? selectTagThisSite : null,
        })
        .then(() => {
          getParticipantTagList && getParticipantTagList();
          getFileList && getFileList();
          alert('Settings saved successfully');
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectTagThisSite = (e: any) => {
    setSelectTagThisSite(e);
  };
  const getTags = () => {
    axios
      .get(`/api/visitor/${conferenceId}/media/tags`)
      .then((response) => {
        console.log('response.data', response.data);
        setTags(
          response.data?.map((tag: any) => ({
            value: tag.id,
            label: tag.label,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTags();
    return () => {};
  }, []);

  useEffect(() => {
    if (isModerator) {
      axios
        .get(`/api/visitor/media/tags/settings/${conferenceId}`)
        .then((response) => {
          console.log('response.data settings', response.data);
          setShowAllTags(response.data.showAllTags);
          setSelectTagThisSite(response.data.selectedTags);
        });
    }
  }, [isModerator]);

  return (
    <Box
      p={2}
      mb={2}
      borderRadius={4}
      style={{
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography variant="h4">File management</Typography>
      <br />
      <Box style={{ backgroundColor: '#ffffff', padding: 16 }}>
        <Typography variant="h6">Edit tags (For moderator only) </Typography>
        <Box mt={2}>
          <Typography variant="body1">Add new tag</Typography>
        </Box>
        <Box display="flex">
          <TextField
            size="small"
            variant="outlined"
            style={{ width: '300px', marginRight: 16 }}
            label="Tag name"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
          />
          <Button
            onClick={handleAddTag}
            variant="contained"
            color="primary"
            ml={2}
          >
            Save tag
          </Button>
        </Box>

        <Box mt={2}>
          <Typography variant="body1">Tag list</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap" mt={1} style={{ gap: '8px' }}>
          {tags.map((tag: any) => (
            <Chip
              key={tag.value}
              label={tag.label}
              onClick={() => handleTagClick(tag)}
              style={{ cursor: 'pointer' }}
            />
          ))}
          {/* {tags.length > 3 && (
          <Button size="small" onClick={() => setShowAllTags(!showAllTags)}>
            {showAllTags ? 'Show less' : 'View more'}
          </Button>
        )} */}
        </Box>
      </Box>
      <Box mt={2} style={{ backgroundColor: '#ffffff', padding: 16 }}>
        <Typography variant="h6">This site </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAllTags}
              onChange={(e) => setShowAllTags(e.target.checked)}
            />
          }
          label="Show all tags"
        />
        <Typography variant="body1" mt={2} mb={2}>
          Choose tags for showing in the site
        </Typography>
        <Select
          isMulti
          value={selectTagThisSite}
          options={tags}
          placeholder="Välj taggar"
          onChange={handleSelectTagThisSite}
          isDisabled={showAllTags}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px' }}
          onClick={() => handleSaveSettings()}
        >
          Save
        </Button>
      </Box>

      {/* Modal for Editing & Deleting Tags */}
      {showModal && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box
            style={{
              padding: 16,
              backgroundColor: 'white',
              maxWidth: 500,
              margin: 'auto',
              marginTop: '20vh',
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">Edit Tag</Typography>
            <TextField
              value={selectedTags?.label || ''}
              onChange={handleChange}
              size="small"
              variant="outlined"
              fullWidth
              style={{ marginTop: 16 }}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                onClick={handleEditTag}
                color="primary"
                variant="contained"
                sx={{ mt: 2 }}
              >
                Update Tag
              </Button>
              <Button
                onClick={handleDeleteTag}
                color="error"
                variant="contained"
                sx={{ mt: 2 }}
              >
                Delete Tag
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default TagManagement;
