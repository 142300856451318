import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';

interface CheckboxControlProps {
  name: string;
  label: string;
  defaultValue?: boolean;
}

export const CheckboxControl: React.FC<CheckboxControlProps> = ({
  name,
  label,
  defaultValue = false,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label={label}
        />
      )}
    />
  );
};
