import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Icon,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IContent } from './contract';
import Select from 'react-select';
import TagManagement from './tagManagement';
import { createUpload } from '@mux/upchunk';
import { VideoDialog } from '@/js/manager/components/media/VideoDialog';
import { fileSizeToHumanReadable, getFileIcon } from '@/js/shared/utils/format';
import { AuthContext } from '@/js/shared/context/AuthContext';
interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      fontSize: '1.0rem',
      ...override.root,
    }),
  })
);
const useStyles = makeStyles(() => ({
  editButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 5,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  imageHolder: {
    flex: '0 0 216px',
    position: 'relative',
    wordBreak: 'break-word',
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 8,
    margin: 4,
    filter: `drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3))`,
  },
  imageLink: {
    display: 'inline-block',
    backgroundImage: 'url(/images/bg_squares.png)',
  },
  image: {
    display: 'block',
    width: 200,
    height: 200,
    borderRadius: 4,
  },
  select: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#ffffffdd',
  },
  inputCopyText: {
    borderRadius: 1,
    border: '1px solid #cccccc',
    backgroundColor: '#eeeeee',
    padding: 3,
    width: '100%',
  },
  fileInputs: {
    width: '100%',
  },
  fileInputName: {
    flex: '0 0 70px',
  },
}));

const thumbStyles = makeStyles(() => ({
  imageLink: {
    display: 'block',
    width: '100%',
    backgroundColor: '#000000',
    paddingBottom: '56.25%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
  converting: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const STATUS = {
  TEMPORARY: 1,
  FILE: 2,
  ENCODING: 6,
  ERROR: 7,
};

const VideoThumb = ({
  file,
  reloadMediaList,
  openVideoDialog,
}: {
  file: any;
  reloadMediaList: () => void;
  openVideoDialog: (file: any) => void;
}) => {
  const classes = thumbStyles();

  const videoUploadStatus = () => {
    axios
      .get(`/api/media/${file.id}/videuploadstatus`)
      .then((response) => {
        if (response.data.status === 'finished') {
          reloadMediaList();
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (file.status !== STATUS.FILE && file.status !== STATUS.ERROR) {
      const interval = setInterval(videoUploadStatus, 4000);
      return () => clearInterval(interval);
    }
  }, [file.status]);

  return (
    <ButtonBase
      className={classes.imageLink}
      style={{
        backgroundImage: `url(/api/media/${file.id}/videothumbnail?reload=${file.status}&access=public)`,
      }}
      onClick={() => openVideoDialog(file)}
    >
      {file.status !== STATUS.FILE && (
        <div className={classes.converting}>
          <CircularProgress />
        </div>
      )}
    </ButtonBase>
  );
};

export const Render = ({
  node: {
    id,
    override,
    content: {
      order,
      reverse,
      show,
      shouldParticipantUpload,
      title,
      header,
      headerDescription,
      subHeader,
      subHeaderDescription,
      tag_prefix,
      edit_tags_button_text,
      edit_file_button_text,
    },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const staticClasses = useStyles();
  const { authState, isModerator } = useContext(AuthContext);
  const [files, setFiles] = useState<any[]>([]);
  const [editTags, setEditTags] = useState(false);
  const [editFiles, setEditFiles] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [participantTags, setParticipantTags] = useState([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState<any>(null);
  const [showNrOfFiles, setShowNrOfFiles] = useState<number>(
    parseInt(show) || 0
  );

  const [videoProgress, setVideoProgress] = useState(0);
  const [error, setError] = useState(null);
  const videoUploadMediaId = useRef(null);
  const videoDialog = useRef();

  const getConferenceUrl = (url_cname: any, name: any) => {
    const port = window.location.port ? `:${window.location.port}` : '';
    if (url_cname) {
      return `//${url_cname}`;
    } else {
      return `//${name}${window.location.hostname.substring(
        window.location.hostname.indexOf('.')
      )}${port}`;
    }
  };

  useEffect(() => {
    getFileList();
    getParticipantTagList();
  }, []);

  const getFileList = () => {
    axios
      .get(
        `/api/visitor/conferences/${conferenceId}/uploadedfiles?order=${
          order || 'date'
        }&reverse=${reverse ? '1' : '0'}`
      )
      .then((response) => {
        setFiles(response.data);
        setUploading(false);
      });
  };

  const getParticipantTagList = () => {
    axios
      .get(`/api/visitor/${conferenceId}/media/tags?participant=true`)
      .then((response) => {
        setParticipantTags(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const filesToShow = useMemo(() => {
  //   return showNrOfFiles === 0 ? files : files.slice(0, showNrOfFiles);
  // }, [files, showNrOfFiles]);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadStart = () => {
    setUploading(true);
    setVideoUploadMediaId(null);
    setVideoProgress(0);
    setError(null);
  };

  const setVideoUploadMediaId = (mediaId: string) => {
    videoUploadMediaId.current = mediaId;
  };

  const videoSigningFinished = (response: any) => {
    setVideoUploadMediaId(response.data.id);
  };

  const videoUploadProgress = (progress: any) => {
    setVideoProgress(Math.floor(progress.detail));
  };

  const setConversionStarted = (mediaId: string) => {
    axios
      .put(`/api/media/${mediaId}/setvideoconverting`)
      .then(() => {
        getFileList();
        setUploading(false);
      })
      .catch(() => {});
  };

  const videoUploadHandler = (fileInfo: any) => {
    setConversionStarted(videoUploadMediaId.current);
  };

  const uploadHandler = () => {
    getFileList();
  };

  const fileName = useMemo(() => {
    if (selectedFile) {
      return selectedFile.name;
    }
    return 'Ingen fil har valts';
  }, [selectedFile]);

  const handleError = (error: any) => {
    console.log('DropZone handleError', error);
    try {
      setError(error.response.data.message);
      setUploading(false);
    } catch (error) {
      console.log('DropZone handleError', error);
    }
  };

  const handleUpload = () => {
    if (selectedFile && selectedTags) {
      try {
        uploadStart();
        axios
          .post(
            `/api/visitor/conferences/${conferenceId}/uploadmediawithtags`,
            {
              encodedFileName: encodeURIComponent(selectedFile.name),
              fileName: selectedFile.name,
              fileType: selectedFile.type,
              fileSize: selectedFile.size,
              tags: [selectedTags.value],
            }
          )
          .then((response) => {
            if (selectedFile.type.includes('video')) {
              videoSigningFinished(response);
              const upload = createUpload({
                file: selectedFile,
                // Normally this would be retrieved via an API request to an endpoint
                // you control that would return an authenticated URL.
                endpoint: response.data.url,
                chunkSize: 5120, // Uploads the file in ~5mb chunks
              });

              upload.on('error', handleError);
              upload.on('progress', videoUploadProgress);
              upload.on('success', videoUploadHandler);
            } else {
              uploadFile(
                selectedFile,
                response.data.signedRequest,
                () => {
                  uploadHandler();
                },
                handleError
              );
            }
            setSelectedFile(null);
          })
          .catch(handleError);
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!selectedTags) alert('No tags selected');
      else if (!selectedFile) alert('No file selected');
    }
  };

  const uploadFile = (
    file: any,
    signedRequest: any,
    callback: any,
    errorHandler: any
  ) => {
    axios
      .put(signedRequest, file)
      .then(() => {
        callback();
      })
      .catch(errorHandler);
  };

  const fileFormats = [
    '.jpeg',
    '.jpg',
    '.png',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.txt',
    '.mp4',
    '.mov',
  ];

  const [checked, setChecked] = useState([]);

  const toggleChecked = (id: any, type: any) => {
    if (checked.find((c) => c.id === id)) {
      setChecked(checked.filter((c) => c.id !== id));
    } else {
      setChecked([...checked, { id, type }]);
    }
  };
  const openVideoDialog = (file: any) => {
    videoDialog?.current?.open(file);
  };

  const renderInfo = (type: string, mediaFile: any) => {
    if (type === 'file') {
      return (
        <>
          <Link
            href={`${window.location.protocol}${getConferenceUrl(
              authState?.user?.conference?.url_cname,
              authState?.user?.conference?.name
            )}/downloadmediafile/${mediaFile.id}`}
          >
            <strong>{mediaFile.original_filename}</strong>
          </Link>{' '}
          <br />({mediaFile.createdAt})
          <br /> File size : {fileSizeToHumanReadable(mediaFile.size)}
          <br /> Uploaded by :{' '}
          {`${mediaFile.first_name} ${mediaFile.last_name}`}
          {editFiles &&
            (isModerator || mediaFile.userId == authState?.user?.id) && (
              <Checkbox
                size="small"
                checked={checked.find((c) => c.id == mediaFile.id)}
                onClick={() => toggleChecked(mediaFile.id, mediaFile.type)}
                className={staticClasses.select}
              />
            )}
        </>
      );
    }
    return (
      <>
        <strong>{mediaFile.original_filename}</strong>
        <br />({mediaFile.createdAt})
        <br /> File size : {fileSizeToHumanReadable(mediaFile.size)}
        <br /> Uploaded by : {`${mediaFile.first_name} ${mediaFile.last_name}`}
        {editFiles &&
          (isModerator || mediaFile.userId == authState.user.id) && (
            <Checkbox
              size="small"
              checked={checked.find((c) => c.id == mediaFile.id)}
              onClick={() => toggleChecked(mediaFile.id, mediaFile.type)}
              className={staticClasses.select}
            />
          )}
      </>
    );
  };

  const deleteMedia = (mediaIds: any) => {
    const confirmed = window.confirm('Really remove these images?');

    if (!confirmed) return;
    const filemediaIds = mediaIds
      .filter((m: any) => m.type === 'file' || m.type === 'image')
      .map((m: any) => m.id);
    const videoIds = mediaIds
      .filter((m: any) => m.type === 'videomux')
      .map((m: any) => m.id);
    if (videoIds.length > 0) {
      axios
        .put(`/api/conferences/${conferenceId}/deletevideofiles`, {
          ids: videoIds,
        })
        .then(() => {
          getFileList();
          setChecked([]);
        })
        .catch(() => {});
    }
    if (filemediaIds.length > 0) {
      axios
        .put(`/api/conferences/${conferenceId}/deletemediafiles`, {
          ids: filemediaIds,
        })
        .then((response) => {
          getFileList();
          setChecked([]);
        })
        .catch(() => {});
    }
  };

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <>
          <Box
            className={staticClasses.editButton}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              {!editTags && isModerator && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setEditTags(!editTags)}
                  startIcon={<Icon>settings</Icon>}
                >
                  {edit_tags_button_text}
                </Button>
              )}
              {editTags && isModerator && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setEditTags(!editTags)}
                  startIcon={<Icon>settings</Icon>}
                >
                  {'Close tag management'}
                </Button>
              )}
            </Box>
            <Box>
              {!editFiles && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setEditFiles(!editFiles)}
                  startIcon={<Icon>settings</Icon>}
                >
                  {edit_file_button_text}
                </Button>
              )}
              {editFiles && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setEditFiles(!editFiles)}
                  startIcon={<Icon>settings</Icon>}
                >
                  {'Close file management'}
                </Button>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            {isModerator && editTags && (
              <TagManagement
                conferenceId={conferenceId}
                isModerator={isModerator}
                getParticipantTagList={getParticipantTagList}
                getFileList={getFileList}
              />
            )}
            {shouldParticipantUpload && (
              <Box
                style={{ backgroundColor: '#f5f5f5' }}
                p={2}
                mb={2}
                borderRadius={4}
              >
                {title && (
                  <>
                    <Typography variant="h4">{title}</Typography>
                    <br />
                  </>
                )}
                {header && (
                  <Typography variant="h6" gutterBottom>
                    1. {header}
                  </Typography>
                )}
                {headerDescription && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ fontStyle: 'italic' }}
                  >
                    {headerDescription}
                  </Typography>
                )}
                {subHeader && (
                  <Typography variant="h6" gutterBottom>
                    {!header ? '1.' : '2.'} {subHeader}
                  </Typography>
                )}
                {subHeaderDescription && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ fontStyle: 'italic' }}
                  >
                    {subHeaderDescription}
                  </Typography>
                )}

                <Box mt={2} style={{ maxWidth: '600px' }}>
                  <Select
                    options={participantTags}
                    placeholder="Välj taggar"
                    onChange={setSelectedTags}
                  />
                </Box>
                <Box mt={2}>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept={fileFormats.join(',')}
                  />
                  <label
                    htmlFor="fileInput"
                    style={{
                      display: 'inline-block',
                      padding: '8px 12px',
                      backgroundColor: '#007bff',
                      color: 'white',
                      cursor: 'pointer',
                      borderRadius: '5px',
                      textAlign: 'center',
                    }}
                  >
                    Välj fil
                  </label>
                  <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                    {fileName}
                  </span>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '10px' }}
                  onClick={handleUpload}
                  disabled={uploading}
                >
                  Ladda upp!
                </Button>
                {uploading && (
                  <Box>
                    <CircularProgress disableShrink /> {videoProgress}%
                  </Box>
                )}
                {error && (
                  <Box>
                    <Typography color="error">{error}</Typography>
                  </Box>
                )}
              </Box>
            )}
            {editFiles && (
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  startIcon={<Icon>delete</Icon>}
                  color="secondary"
                  size="small"
                  onClick={() => deleteMedia(checked)}
                  disabled={checked.length === 0}
                >
                  Delete {checked.length > 0 ? checked.length : ''}
                </Button>
              </Box>
            )}
            {files.length === 0 && 'No files...'}
            {files.map((file) => (
              <Box key={file.id} pb={1}>
                <Box
                  style={{
                    backgroundColor: '#ffffff',
                    padding: 16,
                    borderRadius: 8,
                    marginBottom: 8,
                  }}
                >
                  <Typography variant="h5">
                    {tag_prefix}
                    {file.tagName}
                  </Typography>
                </Box>
                <Box className={staticClasses.list}>
                  {file.mediaFiles.length <= 0 && (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      style={{ width: '100%', padding: 16 }}
                    >
                      No media files found
                    </Box>
                  )}
                  {file.mediaFiles.map((mediaFile: any) => {
                    if (mediaFile.type === 'image') {
                      return (
                        <Box
                          key={file.id}
                          className={staticClasses.imageHolder}
                        >
                          <a
                            className={staticClasses.imageLink}
                            href={`https://${process.env.STATIC_IMAGES}/conferences/${conferenceId}/${mediaFile.source_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={`https://${process.env.STATIC_IMAGES}/conferences/${conferenceId}/${mediaFile.source_id}?fit=max&w=600`}
                              className={staticClasses.image}
                            />
                          </a>
                          <br />
                          <br />
                          {renderInfo(mediaFile.type, mediaFile)}
                        </Box>
                      );
                    } else if (mediaFile.type === 'videomux') {
                      return (
                        <Box
                          key={mediaFile.id}
                          className={staticClasses.imageHolder}
                        >
                          <VideoThumb
                            file={mediaFile}
                            reloadMediaList={getFileList}
                            openVideoDialog={openVideoDialog}
                          />
                          <br />
                          {renderInfo(mediaFile.type, mediaFile)}
                        </Box>
                      );
                    } else if (mediaFile.type === 'file') {
                      return (
                        <Box
                          key={mediaFile.id}
                          className={staticClasses.imageHolder}
                        >
                          <Box>
                            <Icon style={{ fontSize: 200 }}>
                              {getFileIcon(mediaFile.original_filename)}
                            </Icon>
                          </Box>
                          <br />
                          {renderInfo(mediaFile.type, mediaFile)}
                        </Box>
                      );
                    }
                  })}
                </Box>
              </Box>
            ))}

            <VideoDialog ref={videoDialog} />
          </Box>
        </>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
