import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'File Manager',
  icon: 'folder_open',
  type: 'file-list',
  tag: 'components',
  content: {
    order: 'date',
    reverse: false,
    show: 0,
    shouldParticipantUpload: false,
    title: 'Publish for this site',
    header: 'Ladda upp en fil på denna sida.',
    headerDescription:
      'Välj den fil du vill ladda upp och publicera. Tillåtna filformat: jpeg/jpg, png, pdf, word (doc, docx) excel (xls, xlsx), ppt, pptx, txt, mp4, mov.',
    subHeader: 'Välj tag i listan.',
    subHeaderDescription:
      'Låt dina filer taggas när de laddas upp; att publicera är en specifik fillista.',
    tag_prefix: 'File that is uploaded with ',
    edit_tags_button_text: 'Edit tags',
    edit_file_button_text: 'Edit file',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
