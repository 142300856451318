import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useMemo } from 'react';
import { IContent } from './contract';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SwitchControl } from '@/components/switch-control';
import { CheckboxControl } from '@/components/checkbox-control/checkbox-control';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {
      order,
      reverse,
      show,
      shouldParticipantUpload,
      title,
      header,
      headerDescription,
      subHeader,
      subHeaderDescription,
      tag_prefix,
      edit_tags_button_text,
      edit_file_button_text,
    },
  },
  conferenceId,
}: IProps) => {
  return (
    <Fragment>
      Lists files from the Media Library.
      <br />
      <br />
      <SettingsPanel label="Settings" initialOpen>
        <CheckboxControl
          name="shouldParticipantUpload"
          label="Allow participants to upload files"
          defaultValue={shouldParticipantUpload}
        />
        <br />
        <InputControl
          fullWidth
          name="title"
          label="Title (empty to hide)"
          defaultValue={title}
          variant="outlined"
        />
        <br />
        <br />
        <InputControl
          fullWidth
          name="header"
          label="Instruction 1 (empty to hide)"
          defaultValue={header}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="headerDescription"
          label="Instruction 1 description (empty to hide)"
          defaultValue={headerDescription}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="subHeader"
          label="Instruction 2 (empty to hide)"
          defaultValue={subHeader}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="subHeaderDescription"
          label="Instruction 2 description (empty to hide)"
          defaultValue={subHeaderDescription}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="tag_prefix"
          label="Tag prefix"
          defaultValue={tag_prefix}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="edit_tags_button_text"
          label="Edit tags button text"
          defaultValue={edit_tags_button_text}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="edit_file_button_text"
          label="Edit file button text"
          defaultValue={edit_file_button_text}
          variant="outlined"
        />
        <br />
        <RadioGroupControl
          row
          label="Order"
          name="order"
          defaultValue={order}
          options={[
            {
              label: 'By upload date',
              value: 'date',
            },
            {
              label: 'By file name',
              value: 'filename',
            },
          ]}
        />
        <br />
        <SwitchControl
          name="reverse"
          label="Reverse order"
          defaultValue={reverse}
          options={[true, false]}
        />
        <br />
        <br />
        {/* <InputControl
          name="show"
          type="number"
          label="Max nr of files to show"
          defaultValue={show}
          variant="outlined"
        />{' '}
        0 = Show all files
        <br /> */}
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
