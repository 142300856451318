import { VideoPlayer } from '@/components/video-player';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import React, { forwardRef, useImperativeHandle, useState } from 'react';

const getStyles = makeStyles(() => ({
  content: {
    width: '80vw',
    maxWidth: 550,
  },
}));

export const VideoDialog = forwardRef(({}, ref) => {
  const classes = getStyles();
  const [settings, setSettings] = useState({ open: false, data: null });

  useImperativeHandle(ref, () => ({
    open: (data: any) => {
      setSettings({ open: true, data: data });
    },
  }));

  const handleClose = () => {
    setSettings({ open: false, data: null });
  };
  console.log('VideoDialog', settings);
  return (
    <Dialog
      open={settings.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'video file'}</DialogTitle>
      <DialogContent className={classes.content}>
        {settings.data && (
          <VideoPlayer
            source={
              settings.data?.type === 'live_stream'
                ? settings.data?.source
                : {
                    src: `/api/media/${settings.data.id}/videourl`,
                    type: 'application/x-mpegURL',
                    poster: `/api/media/${settings.data.id}/videothumbnail?access=public`,
                  }
            }
          ></VideoPlayer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
});
VideoDialog.displayName = 'VideoDialog';
